import script from "./ShowDetail.vue?vue&type=script&setup=true&lang=js"
export * from "./ShowDetail.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QBtn,QAvatar,QImg,QChip,QIcon,QSelect,QToggle,QExpansionItem,QCardSection,QDialog,QSeparator});qInstall(script, 'directives', {ClosePopup});
