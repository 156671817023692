<template>
	<q-layout view="hHh Lpr lff">
		<WebBorder />
		<q-page-container>
			<q-page padding>
				<router-view :key="$route.fullPath"></router-view>
				<q-page-scroller position="bottom-right" :scroll-offset="150" :offset="[18, 50]">
					<q-btn padding="xs" fab icon="keyboard_arrow_up" :color="state.color" />
				</q-page-scroller>
			</q-page>
		</q-page-container>
	</q-layout>
</template>

<script setup>
	import WebBorder from './components/WebBorder.vue'
	import { useState } from './stores/webStore';
	const state = useState();
</script>
