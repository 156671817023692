<template>

    <!------------------- Loading ------------------------>
    <div v-if="loading" class="col-12 q-pt-lg flex flex-center">
        <q-circular-progress
            indeterminate
            :color="state.color"
            size="75px"
            :thickness="0.6"
            center-color="grey-7"
            class="q-ma-md"
        />
    </div>

    <div class="q-pa-sm q-gutter-y-md" style="max-width: 500px">
        <q-tabs
            v-model="tab"
            dense
            align="justify"
            :class="'text-'+state.color"
        >
            <q-tab name="master" label="Master" @click="card='master'" />
            <q-tab name="visa" label="Visa" @click="card='visa'" />
        </q-tabs>

        <q-input 
            v-model="fee"
            label="Bank Fee (%)"
            type="number"
            style="width:150px"
            clearable 
            class="q-pl-sm"
            @clear="fee='0';bank='0'"
        />
        <div class="q-ma-none q-pt-sm">
            <q-radio v-model="bank" size="sm" val="0" label="0%" @click="fee=bank" />
            <q-radio v-model="bank" size="sm" val="2.5" label="2.5%"  @click="fee=bank"/>
            <q-radio v-model="bank" size="sm" val="3.5" label="3.5%" @click="fee=bank" />
        </div>

        <template v-for="(item,index) in items" :key="index" >
            <div v-if="item.show || showMore" class="row">
                <img :src="'/images/flag/'+item.code.toLowerCase()+'.svg'" 
                    width="30px" class="q-pa-none q-mr-sm" 
                    style="width: 30px;">
                <q-input
                    v-model="item.cal"
                    :label="item.country"
                    type="number"
                    dense
                    clearable
                    style="width:100px"
                >
                </q-input>
                <span v-if="card=='master'" class="q-pt-md q-pl-sm">
                    {{item.currency}} = {{(item.baht*item.cal*(fee/100+1)).toFixed(2)}} THB
                </span>
                <span v-if="card=='visa'" class="q-pt-md q-pl-sm">
                    {{item.currency}} = {{(item.visa*item.cal*(fee/100+1)).toFixed(2)}} THB
                </span>
            </div>
        </template>

        <q-btn v-if="!showMore" label="Show More" :color="state.color" dense @click="showMore=true"></q-btn>
        <q-btn v-if="showMore" label="Show Less" :color="state.color" dense @click="showMore=false"></q-btn>
        
    </div>

</template>

<script setup>
    import { ref,getCurrentInstance } from 'vue';
    import { useState } from '../stores/webStore';
	import { useRoute } from 'vue-router';
    import axios from 'axios'
	const route = useRoute();
	const state = useState();
	
	state.setRoute(route.name);

    let hostUrl = getCurrentInstance().appContext.config.globalProperties.$hostUrl;
    let tab = ref('master');
    let card = ref('master');
    let fee = ref('0');
    let bank = ref('0');
    let items = ref([]);
    let showMore = ref(false);
    let loading = ref(false);

    state.title = "Currency Exchange Rate";
    state.desc = "Currency Exchange Rate to Thai Baht USD , ARS , INR , TRY etc.";
    state.image = "/images/logo_"+state.device+".png"

    getData();

    async function getData (){
        loading.value = true;
        let formData = new FormData();
        await axios.post(hostUrl+'api/getCurrency', formData)
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                var result = response.data;
                // console.log(result)
                if(result.type == 'success'){
                    items.value = result.data;
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
        loading.value = false;
    }

</script>