import script from "./ExchangeView.vue?vue&type=script&setup=true&lang=js"
export * from "./ExchangeView.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCircularProgress,QTabs,QTab,QInput,QRadio,QBtn});
