<template>
    <div class="col-12 q-pt-lg flex flex-center">
        <q-circular-progress
            indeterminate
            color="grey"
            size="75px"
            :thickness="0.6"
            center-color="grey-7"
            class="q-ma-md"
        />
    </div>
</template>

<script setup>
    import { getCurrentInstance} from 'vue';
    import { useState } from '../stores/webStore';
    import { useRoute,useRouter } from 'vue-router';
    import axios from 'axios'
    import liff from '@line/liff';

    const route = useRoute();
    const router = useRouter();
    const state = useState();
    state.setRoute(route.name);

    // let inter = getCurrentInstance().appContext.config.globalProperties.$inter;
    let hostUrl = getCurrentInstance().appContext.config.globalProperties.$hostUrl;

    // if(inter){
    //     let user = {
    //         uid : '1',
    //         sub: 'U60d965883015593f6e16cd938671e533',
    //         username : 'numgamer',
    //         picture : 'https://profile.line-scdn.net/0hZJmlIqozBWVQFS8Z28N6MmxQCwgnOwMtKHJNBicVXFcudxYwZSZPAnVCC1EuJkVnaHIdACEcXFJ0',
    //     }
    //     state.setUser(user);
    // }

    if(localStorage.getItem("user")){
        login(JSON.parse(localStorage.getItem("user")));
    } else {
        liff.init({ liffId: "1656959430-j3bG1wdv" }, async () => {
            if (liff.isLoggedIn()) {
                const params = new URLSearchParams()
                params.append('id_token', liff.getIDToken())
                params.append('client_id', '1656959430')
                const headers = { 
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
                await axios.post(
                    'https://api.line.me/oauth2/v2.1/verify',
                    params,
                    headers
                ).then(async function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        let result = response.data;
                        result.uid = '';
                        result.store = state.store;
                        login(result);
                    }
                }).catch(function () {
                    liff.logout()
                    liff.login()
                })
            } else {
                liff.login();
            }
        }, err => console.error(err.code));
    }

    async function login(obj){
        let user = {
            'uid': obj.uid,
            'sub': obj.sub,
            'picture': obj.picture,
            'store': state.store,
        }
        await axios.post(hostUrl+'api/login', JSON.stringify(user))
        .then(function (response) {
            if(!response.data){
                alert("ERROR Please contact admin")
            }else{
                state.setUser(response.data.data);
                router.push('/'+state.device);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

</script>